import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import * as styles from './HeroFull.module.scss'

const HeroFull = ({ children, img, imgSm, alt, brand, brandSm }) => {
  return (
    <div className={styles.hero}>
      <div className={clsx(styles.wrapper, 'center col-12')}>
        <div className={clsx(styles.title, 'col-r-7-12')}>
          {children}
        </div>
      </div>

      <div className={styles.imgBLock}>
        <GatsbyImage
          image={getImage(img)}
          alt={alt}
          objectFit="cover"
          className={clsx(styles.img, imgSm && styles.imgM)}
          quality="100"
        />
        {imgSm &&
          <GatsbyImage
            image={getImage(imgSm)}
            alt={alt}
            objectFit="cover"
            className={clsx(styles.img, styles.imgSm)}
            quality="100"
          />
        }
        {brand &&
          <div className={styles.brand}>
            <div className={clsx(styles.brand_wrapper, 'center col-12 l-s-0 r-s-0')}>
              <div className={clsx(styles.brand_img, brandSm && styles.brand_img_m, 'col-r-7-12')}>
                <GatsbyImage
                  image={getImage(brand)}
                  alt=" "
                  objectFit="cover"
                />
              </div>
              {brandSm &&
                <div className={clsx(styles.brand_img, styles.brand_img_sm, 'col-r-7-12')}>
                  <GatsbyImage
                    image={getImage(brandSm)}
                    alt=" "
                    objectFit="cover"
                  />
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div >
  )
}

HeroFull.propTypes = {
  children: PropTypes.node.isRequired,
  img: PropTypes.object.isRequired,
  imgSm: PropTypes.object,
  alt: PropTypes.string.isRequired,
  brand: PropTypes.object,
  brandSm: PropTypes.object,
}

export default HeroFull
