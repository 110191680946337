import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as styles from './InfoBanner.module.scss'
import AttentionIcon from '../../icons/inline/icon-attention.svg'
import SvgIcon from '../SvgIcon/SvgIcon'

const InfoBanner = ({ type, title, children, className }) => {
  return (
    <div className={clsx(styles.banner, styles[`type_${type}`], className)}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <SvgIcon size="24" >
            <AttentionIcon />
          </SvgIcon>
        </div>
        <div className={styles.info}>
          <p className={styles.title}>
            {title}
          </p>
          <div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

InfoBanner.propTypes = {
  type: PropTypes.oneOf(['black']),
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
}

InfoBanner.defaultProps = {
  type: 'black'
}

export default InfoBanner