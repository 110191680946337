/* eslint-disable react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Typography from 'components/Typography'
import InfoBanner from 'components/InfoBanner'
import HeroFull from 'components/HeroFull'
import Quote from 'components/Quote'
import CtaCustomize from 'components/CtaCustomize'
import RedirectLink from 'components/RedirectLink'
import MarkdownTemplate from 'components/MarkdownTemplate'

import * as styles from './styles.module.scss'
import useUtagView from '../../hooks/useUtagView'

const SignatureTemplate = ({ data, pageContext, location }) => {
  useUtagView('quebec_high_value', 'high value');
  return (
    <>
      <SEO
        title={data.pageData.frontmatter.metaTitle}
        description={`${data.pageData.frontmatter.metaDescription}`}
      />
      <Layout
        mainClass="home-page"
        headerBtn={data.pageData.frontmatter.headerBtn}
        headerBtnHref={data.pageData.frontmatter.headerBtnHref}
        pathname={location.pathname}
        langCode={pageContext.lang}
      >
        <InfoBanner
          type="black"
          title={data.bannerContent.edges[0].node.frontmatter.title}
          className={styles.info_banner}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data.bannerContent.edges[0].node.html,
            }}
          />
        </InfoBanner>

        <HeroFull
          img={data.pageData.frontmatter.imgM}
          imgSm={data.pageData.frontmatter.imgSM}
          alt={data.pageData.frontmatter.imgAlt}
          brand={data.pageData.frontmatter.imgBrandHeroM}
          brandSm={data.pageData.frontmatter.imgBrandHeroSM}
        >
          <Typography
            variant="h1"
            isDisplay
            fontFamily="expanded"
            fontWeight="300"
            className={clsx(styles.hero_title, 'l-64 l-l-s l-m-0 t-m b-s')}
          >
            {data.pageData.frontmatter.title}
          </Typography>
        </HeroFull>

        <section className="center col-12 t-xl b-l t-m-m t-s-s">
          <Quote className="center col-r-8-12 col-l-fixed-8 col-m-fw t-l b-l">
            <Typography variant="txt2" component="p">
              {data.pageData.frontmatter.quote}
            </Typography>
          </Quote>
        </section>

        <section className="center col-12 t-l b-xl">
          <div className="center col-r-8-12 col-l-fixed-8 col-m-fw">
            <div className="b-m">
              <Typography
                variant="h2"
                fontFamily="expanded"
                fontWeight="300"
                className="t-s-m"
              >
                {data.solutionContent.edges[0].node.frontmatter.title}
              </Typography>
              <Typography variant="txt2" isLead>
                {data.solutionContent.edges[0].node.frontmatter.subtitle}
              </Typography>
            </div>
            <MarkdownTemplate variant="four">
              <div
                className="b-l"
                dangerouslySetInnerHTML={{
                  __html: data.solutionContent.edges[0].node.html,
                }}
              />
            </MarkdownTemplate>
          </div>
        </section>

        <CtaCustomize
          background="disabled"
          title={data.pageData.frontmatter.ctaTitle}
          titleType="light"
          titleVariant="h3"
          text={data.pageData.frontmatter.ctaSubtitle}
          className="t-32 b-32"
          content={data.ctaContent.edges}
        />

        <section className="center col-12">
          <RedirectLink
            text={data.pageData.frontmatter.refer}
            link="https://www.hagerty.ca/"
            linkText={data.pageData.frontmatter.referBtn}
            className="t-32 b-16"
            classItem="t-s-0"
            classText="t-s-0 b-s-s"
            classLink="fw-500"
          />
        </section>

        <section className="center col-12 ta-l t-32 b-32">
          <div className="center col-r-8-12 col-l-fixed-8 col-m-fw b-m-s t-m-s b-s-0 t-s-0">
            <MarkdownTemplate>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.pageData.html,
                }}
              />
            </MarkdownTemplate>
          </div>
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query (
    $absolutePath: String!
    $ctaPath: String!
    $bannerPath: String!
    $solutionPath: String!
  ) {
    pageData: markdownRemark(fileAbsolutePath: { regex: $absolutePath }) {
      frontmatter {
        headerBtn
        headerBtnHref
        title
        metaTitle
        metaDescription
        imgAlt
        imgM {
          childImageSharp {
            gatsbyImageData
          }
        }
        imgSM {
          childImageSharp {
            gatsbyImageData
          }
        }
        imgBrandHeroM {
          childImageSharp {
            gatsbyImageData
          }
        }
        imgBrandHeroSM {
          childImageSharp {
            gatsbyImageData
          }
        }
        quote
        ctaTitle
        ctaSubtitle
        refer
        referBtn
      }
      html
    }
    ctaContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $ctaPath } }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            imageAlt
            icon
            text
            btnText
            btnIcon
            btnVariant
            href
            external
          }
        }
      }
    }
    bannerContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $bannerPath } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
    solutionContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $solutionPath } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
          }
          html
        }
      }
    }
  }
`

SignatureTemplate.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.exact({
    pageData: PropTypes.exact({
      frontmatter: PropTypes.exact({
        headerBtn: PropTypes.string,
        headerBtnHref: PropTypes.string,
        title: PropTypes.string,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        imgAlt: PropTypes.string,
        imgM: PropTypes.object,
        imgSM: PropTypes.object,
        imgBrandHeroM: PropTypes.object,
        imgBrandHeroSM: PropTypes.object,
        quote: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaSubtitle: PropTypes.string,
        refer: PropTypes.string,
        referBtn: PropTypes.string,
      }),
      html: PropTypes.any,
    }),
    ctaContent: PropTypes.exact({
      edges: PropTypes.array,
    }),
    bannerContent: PropTypes.exact({
      edges: PropTypes.array,
    }),
    solutionContent: PropTypes.exact({
      edges: PropTypes.array,
    }),
  }).isRequired,
}

export default SignatureTemplate
