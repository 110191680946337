// extracted by mini-css-extract-plugin
export var brand = "HeroFull-module--brand--INz6g";
export var brand_img = "HeroFull-module--brand_img--jy3SV";
export var brand_img_m = "HeroFull-module--brand_img_m--znlXh";
export var brand_img_sm = "HeroFull-module--brand_img_sm--OTMvJ";
export var brand_wrapper = "HeroFull-module--brand_wrapper--8IRSo";
export var hero = "HeroFull-module--hero--sStiB";
export var img = "HeroFull-module--img--JIlJH";
export var imgBLock = "HeroFull-module--imgBLock--0irlB";
export var imgM = "HeroFull-module--imgM--l6GKr";
export var imgSm = "HeroFull-module--imgSm--I0tx-";
export var title = "HeroFull-module--title--p9EUZ";
export var wrapper = "HeroFull-module--wrapper--h7a59";