import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as styles from './Quote.module.scss'

const Quote = ({ children, variant, className }) => (
  <blockquote className={clsx(styles.quote, className)}>
    {/* eslint-disable-next-line react/self-closing-comp */}
    <div className={styles.border}></div>
    <div
      className={clsx(variant && styles[`children_${variant}`])}
    >
      {children}
    </div>
  </blockquote>
)

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default']),
  className: PropTypes.string,
}

Quote.defaultProps = {
  variant: 'default'
}

export default Quote