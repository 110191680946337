import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SvgIcon from 'components/SvgIcon'
import Button from 'components/Button'
import ButtonIconMove from 'components/ButtonIconMove'
import Typography from 'components/Typography'
import IconPhone from '../../../icons/inline/icon-phone.svg'
import * as styles from './CtaCustomItem.module.scss'

const CtaCustomItem = ({
  image,
  title,
  text,
  btnText,
  btnIcon,
  btnVariant,
  href,
  external,
}) => {
  return (
    <div className={clsx(styles.item)}>
      <div className="t-s t-s-s b-s b-s-s">
        <SvgIcon size="64" responsiveSize="s-48">
          {image}
        </SvgIcon>
      </div>

      <Typography variant="h3" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="txt2" className={styles.text}>
        {text}
      </Typography>
      {btnVariant === 'btnArrow' && (
        <div className="t-m b-m">
          <ButtonIconMove
            href={href}
            external={external}
            variant="primary"
            sizeResponsive={['l-ml','m-l', 's-s']}
          >
            <span>{btnText}</span>
          </ButtonIconMove>
        </div>
      )}
      {(!btnVariant || btnVariant === 'linkPhone') && (
        <div className="t-m b-m t-s-m b-s-m">
          <Button
            variant="secondary"
            href={href}
            external={external}
            className={styles.link}
          >
            {btnIcon && (
              <SvgIcon size="16">
                <IconPhone />
              </SvgIcon>
            )}
            <span>{btnText}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

CtaCustomItem.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  btnIcon: PropTypes.string,
  btnVariant: PropTypes.oneOf(['btnArrow', 'linkPhone']),
  href: PropTypes.string,
  external: PropTypes.bool,
}

export default CtaCustomItem
