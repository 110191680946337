import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as styles from './CtaCustomize.module.scss'
import CtaCustomItem from './CtaCustomItem'
import Typography from '../Typography'
import IconCall from '../../icons/inline/icon-call-agent.svg'
import IconAgent from '../../icons/inline/icon-quote-online.svg'

const CtaCustomize = ({
  background,
  title,
  titleVariant,
  titleType,
  text,
  className,
  content,
}) => {
  const icons = {
    iconAgent: <IconAgent />,
    iconCall: <IconCall />,
  }
  return (
    <div className={className}>
      <div className="center col-12">
        <div className={clsx(styles.cta, styles[`cta_${background}`])}>
          <div className={clsx(styles.left)}>
            <Typography
              variant={titleVariant}
              className={clsx({
                [styles[`title_${titleType}`]]: titleType,
              })}
            >
              {title}
            </Typography>
            <Typography variant="txt2">
              {text}
            </Typography>
          </div>
          <div className={clsx(styles.ctaItems)}>
            {content.map((item) => (
              <CtaCustomItem
                key={item.node.frontmatter.id}
                image={icons[item.node.frontmatter.icon]}
                title={item.node.frontmatter.title}
                text={item.node.frontmatter.text}
                btnText={item.node.frontmatter.btnText}
                btnIcon={item.node.frontmatter.btnIcon}
                btnVariant={item.node.frontmatter.btnVariant}
                href={item.node.frontmatter.href}
                external={item.node.frontmatter.external}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

CtaCustomize.propTypes = {
  title: PropTypes.any,
  titleType: PropTypes.oneOf(['light']),
  titleVariant: PropTypes.string,
  background: PropTypes.oneOf(['disabled']),
  text: PropTypes.any,
  className: PropTypes.any,
  content: PropTypes.array,
}

CtaCustomize.defaultProps = {
  titleVariant: 'h2',
}

export default CtaCustomize
